import './CustomerComponent.css';
import Button from 'devextreme-react/button';
import React, { Component } from 'react';
import DataGrid, {
    Column, ColumnFixing,
    Editing, EmailRule,
    FilterRow,
    HeaderFilter,
    Lookup,
    MasterDetail,
    Paging,
    RemoteOperations,
    RequiredRule,
    Scrolling, Sorting
} from "devextreme-react/data-grid";
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import config from "../../common/config/config";
import DataSource from "devextreme/data/data_source";
import { Item, TabPanel } from "devextreme-react/tab-panel";
import { startSync } from '../../common/syncUtils';

const dataSource = {
    store: AspNetData.createStore({
        key: 'id',
        loadUrl: `${config.apiUrl}/customers`,
        insertUrl: `${config.apiUrl}/customers`,
        updateUrl: `${config.apiUrl}/customers`,
        deleteUrl: `${config.apiUrl}/customers`
    })
};

const dealersDataSource = {
    store: AspNetData.createStore({
        loadUrl: `${config.apiUrl}/dealers`,
    }),
    paginate: true,
    pageSize: config.pageSize
};

const qmAddressDataSource = [0, 1, 2];
const salutationIdDataSource = [0, 1, 2, 4];

const endpoint = 'job/start/transactional/customer-import'

class CustomerComponent extends Component {
    handleSync = () => {
        try {
          startSync(endpoint);
        } catch (error) {
          console.error(error);
          
        }
      };
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 0,
            pageLimit: 10,
            totalRecords: 0,
            pageCount: 0,
            pageNeighbours: 2,
            showFilterRow: true,
            showHeaderFilter: true,
            currentFilter: 'auto',
            selectTextOnEditStart: true,
            startEditAction: 'click',
            errors: [],
            isAuthenticated: false,
            user: undefined,
            readOnly: true,
            height: window.innerHeight - config.pxToSubtract
        };
    }


    

    render() {
        return (
            <div>
                <h1>Customers</h1>
                <div>
                    <ul style={{ listStyleType: "none" }}>
                        {this.state.errors.map((value, index) => {
                            return <li style={{ color: "red" }} key={index}>{value}</li>
                        })}
                    </ul>
                </div>
                <div className="datagrid">
                    <Button text="Sync" onClick={this.handleSync} />

                    <DataGrid id="gridContainer"
                        dataSource={dataSource}
                        showBorders={true}
                        onEditorPreparing={this.onEditorPreparing}
                        rowAlternationEnabled={true}
                        showRowLines={true}
                        allowColumnResizing={true}
                        columnResizingMode={'nextColumn'}
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        height={this.state.height}
                    >
                        <ColumnFixing enabled="true" />
                        <Sorting mode="multiple" />
                        <RemoteOperations paging={true} filtering={true} sorting={true} grouping={true} />
                        <Paging defaultPageSize={config.defaultPageSize} />
                        <Scrolling mode="virtual" rowRenderingMode="virtual" />
                        <Editing
                            mode="batch"
                            allowUpdating={!this.state.readOnly}
                            allowAdding={!this.state.readOnly}
                            allowDeleting={!this.state.readOnly}
                        />
                        <FilterRow visible={this.state.showFilterRow}
                            applyFilter={this.state.currentFilter}
                            showAllText="" />
                        <HeaderFilter visible={this.state.showHeaderFilter} />

                        <Column dataField="id" dataType="number" caption="Customer Id">
                            <RequiredRule message="Customer Id is required" />
                            <HeaderFilter allowSearch={true} />
                        </Column>
                        <Column dataField="groupNo" />
                        <Column dataField="dealerNo">
                            <Lookup
                                dataSource={dealersDataSource}
                            />
                        </Column>
                        <Column dataField="firstName" />
                        <Column dataField="lastName" />
                        <Column dataField="companyName" />
                        <Column dataField="email1">
                            <EmailRule />
                        </Column>
                        <Column dataField="email2">
                            <EmailRule />
                        </Column>
                        <Column dataField="email3">
                            <EmailRule />
                        </Column>
                        <Column dataField="phone1" />
                        <Column dataField="phone2" />
                        <Column dataField="phone3" />
                        <Column dataField="city" />
                        <Column dataField="country" />
                        <Column dataField="zipCode" />
                        <Column dataField="street" />
                        <Column dataField="houseNo" />
                        <Column dataField="externalCustomerNumber" />
                        <Column dataField="qmAddress">
                            <RequiredRule message="qmAddress is required" />
                            <Lookup
                                dataSource={qmAddressDataSource}
                            />
                        </Column>
                        <Column dataField="title" />
                        <Column dataField="salutationId">
                            <RequiredRule message="salutationId is required" />
                            <Lookup
                                dataSource={salutationIdDataSource}
                            />
                        </Column>

                        <MasterDetail
                            enabled={true}
                            component={DetailTemplate}
                        />
                    </DataGrid>
                </div>
            </div>
        );
    };

    onEditorPreparing = (event) => {
        if (event.dataField !== "id" && event.dataField !== "groupNo") {
            return;
        }
        if (event.row !== undefined) {
            if (!event.row.isNewRow) {
                event.editorOptions.disabled = true;
            }
            if (event.dataField === "groupNo" && event.row.isNewRow) {
                event.editorOptions.disabled = true;
            }
        }
    };

    componentDidMount() {
        fetch(`${config.authUrl}`)
            .then(response => {
                response.json()
                    .then(body => {
                        if (body['authenticated'] === false) {
                            this.setState({ isAuthenticated: false });
                            window.location.reload();
                        } else {
                            this.setState({
                                isAuthenticated: true,
                                readOnly: body.readOnly
                            });
                        }
                    });
            });
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        this.setState({ height: parseInt(window.innerHeight) - config.pxToSubtract });
    }
}

class DetailTemplate extends Component {

    render() {
        return (
            <TabPanel>
                <Item title="Emails" render={this.renderEmailsTab} />
            </TabPanel>
        );
    }

    renderEmailsTab = () => {
        return <EmailsTab customerId={this.props.data.key} />;
    };

}

class EmailsTab extends Component {

    constructor(props) {
        super(props);
        this.emailsCustomerId = props.customerId;
        this.state = {
            customerId: props.customerId,
            readOnly: true
        };
        this.emailsDataSource = new DataSource({
            store: AspNetData.createStore({
                key: ['email', 'last_event_date'],
                loadUrl: `${config.apiUrl}/customers/emails?id=` + this.emailsCustomerId,
                insertUrl: `${config.apiUrl}/customers/emails?id=` + this.emailsCustomerId,
                updateUrl: `${config.apiUrl}/customers/emails?id=` + this.emailsCustomerId,
                deleteUrl: `${config.apiUrl}/customers/emails?id=` + this.emailsCustomerId
            })
        });
    }

    render() {
        return (
            <React.Fragment>
                <DataGrid
                    dataSource={this.emailsDataSource}
                    showBorders={true}
                    columnAutoWidth={true}
                    onSaving={this.onSaving}
                    rowAlternationEnabled={true}
                    showRowLines={true}
                    allowColumnResizing={true}
                    columnResizingMode={'nextColumn'}
                    allowColumnReordering={true}
                >
                    <Editing
                        mode="batch"
                        allowUpdating={!this.state.readOnly}
                        allowAdding={!this.state.readOnly}
                        allowDeleting={!this.state.readOnly}
                    />
                    <Column dataField="email">
                        <EmailRule />
                    </Column>
                    <Column dataField="last_event_date" dataType="date" format="yyyy-MM-dd" allowHeaderFiltering={false}>
                    </Column>
                </DataGrid>
            </React.Fragment>
        );
    }

    componentDidMount() {
        fetch(`${config.authUrl}`)
            .then(response => {
                response.json()
                    .then(body => {
                        if (body['authenticated'] === false) {
                            this.setState({ isAuthenticated: false });
                            window.location.reload();
                        } else {
                            this.setState({
                                isAuthenticated: true,
                                readOnly: body.readOnly
                            });
                        }
                    });
            });
    }
}

export default CustomerComponent;